function Footer() {
  return (
    <footer>
      <div>
        <h2>Dirección</h2>
        <p>Hornos 1460, Barracas, Ciudad de Buenos Aires Argentina</p>
        <h2>Teléfono</h2>
        <p>4840-7060</p>
        <h2>Email</h2>
        <p>trentoinsumosmedicos@gmail.com</p>
      </div>
    </footer>
  );
}

export default Footer;
